import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../../../components/PageTitle';
import { EmailTemplateService } from '../../../services/EmailTemplateService';
import { EmailTemplate } from '../../../entities/EmailTemplate';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Body } from './Body';
import { UpsertEmailTemplateDto } from './UpsertEmailTemplateDto';
import { handleFormErrors } from '../../../services/ApiService/handler';
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from '../../../components/FormErrorMessage';
import { Card } from '../../../components/Card';
import { Footer } from './Footer';
import { AttachmentDto } from '../../CandidatePage/CandidateActions/SendEmailModal/AttachmentDto';

interface PropTypes {
  attachmentsEnabled: boolean;
  edit: boolean;
  emailTemplate: EmailTemplate;
}

async function loadEmailTemplateOptions(): Promise<EmailTemplate[]> {
  return await EmailTemplateService.list();
}

function getInitEmailTemplate(
  emailTemplate?: EmailTemplate,
): UpsertEmailTemplateDto {
  return {
    id: emailTemplate?.id,
    name: emailTemplate?.name,
    emailTemplateReferenceId: emailTemplate?.emailTemplateReferenceId,
    subject: emailTemplate?.subject,
    subjectText: emailTemplate?.subjectText,
    template: emailTemplate?.template,
    templateHtml: emailTemplate?.templateHtml,
    attachments: emailTemplate?.attachments,
  };
}

function Title(props: { edit: boolean; name?: string }) {
  if (props.edit) {
    return (
      <PageTitle
        text={
          <>
            <span>Edit / </span>
            <span className='text-dark-200'>{props.name}</span>
          </>
        }
      />
    );
  } else {
    return <PageTitle text={'Add Email Template'} />;
  }
}

export default function SetupEmailTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  const {
    control,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    setError,
  } = useForm<UpsertEmailTemplateDto>({
    mode: 'onChange',
    defaultValues: getInitEmailTemplate(props.emailTemplate),
  });

  useEffect(() => {
    (async () => {
      setEmailTemplates(await loadEmailTemplateOptions());

      setIsLoading(false);
    })();
  }, []);

  const onSubmit = handleSubmit(
    async (
      data: UpsertEmailTemplateDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      setIsLoading(true);

      try {
        await EmailTemplateService.upsert(data);

        window.location.href = '/organization_settings/email_templates';
      } catch (e: unknown) {
        handleFormErrors(e, setError);
      }

      setIsLoading(false);
    },
  );

  return (
    <Row className='row g-3 mb-4 align-items-center justify-content-between'>
      {errors.name?.message && (
        <FormErrorMessage
          error={errors}
          errorMessage={{
            title: `${errors.name?.message}`,
            detail: 'Please change the email template name',
          }}
        />
      )}
      <Title edit={props.edit} name={props.emailTemplate?.name} />
      <Card>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Body
              control={control}
              emailTemplates={emailTemplates}
              errors={errors}
              isValid={isValid}
              onCancel={() =>
                (window.location.href =
                  '/organization_settings/email_templates')
              }
              onSubmit={onSubmit}
              register={register}
              setHtml={(html: string) => setValue('templateHtml', html)}
              setSubjectText={(subject: string) =>
                setValue('subjectText', subject)
              }
              setAttachments={(attachments: AttachmentDto[]) =>
                setValue('attachments', attachments)
              }
            />
            {props.attachmentsEnabled && (
              <Footer control={control} watch={watch} />
            )}
          </>
        )}
      </Card>
    </Row>
  );
}
