import React from 'react';
import { OfferForm } from '..';
import { OfferLetter } from './OfferLetter';
import { OfferLetterTemplate } from '../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { OfferDetails } from './OfferDetails';
import { HorizontalStepper } from '../../../../../components/HorizontalStepper';
import { OfferEmail } from './OfferEmail';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { OfferSummary } from './OfferSummary';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

interface PropTypes {
  applicationId: number;
  elementIdentifier: string;
  form: OfferForm;
  isLoading: boolean;
  step: number;
  to: string;
  files: File[];
  removeAttachmentFileByIndex: (index: number) => void;
  setForm: (form: OfferForm) => void;
  setValidStep: (valid: boolean) => void;
}

const STEPPER_ITEMS = [
  {
    title: 'Offer Letter',
    subtitle: 'Select Offer Letter',
  },
  {
    title: 'Offer Details',
    subtitle: 'Add Offer Details',
  },
  {
    title: 'Offer Email',
    subtitle: 'Define Offer Email',
  },
];

function BodySelector(props: PropTypes) {
  if (props.isLoading) {
    return <LoadingSpinner />;
  }

  switch (props.step) {
    case 0:
      return (
        <OfferLetter
          setIsValid={props.setValidStep}
          offerLetterTemplate={props.form.offerLetterTemplate}
          setOfferLetterTemplate={(offerLetterTemplate: OfferLetterTemplate) =>
            props.setForm({
              ...props.form,
              to: props.to,
              offerLetterTemplate: offerLetterTemplate,
            })
          }
        />
      );
    case 1:
      return (
        <OfferDetails
          offerLetterTemplate={props.form.offerLetterTemplate}
          setForm={props.setForm}
          form={props.form}
          setIsValid={props.setValidStep}
        />
      );
    case 2:
      return (
        <OfferEmail
          applicationIds={[props.applicationId]}
          form={props.form}
          files={props.files}
          removeAttachmentFileByIndex={props.removeAttachmentFileByIndex}
          setForm={props.setForm}
          setIsValid={props.setValidStep}
        />
      );
  }
}

export function BodyWrapper(props: PropTypes) {
  return props.step < STEPPER_ITEMS.length ? (
    <>
      <HorizontalStepper
        displayInModal
        items={STEPPER_ITEMS}
        activeIndex={props.step}
      />
      <div
        id={props.elementIdentifier}
        className={classNames(
          styles[props.step == 2 ? 'modal-email' : 'modal-body'],
          'px-4',
          'mb-3',
        )}
      >
        <BodySelector {...props} />
      </div>
    </>
  ) : (
    <OfferSummary
      applicationId={props.applicationId}
      form={props.form}
      isLoading={props.isLoading}
    />
  );
}
