import React, { useEffect, useState } from 'react';
import { EmailRichTextEditor } from './EmailRichTextEditor';
import { EmailHeader } from './EmailHeader';
import { SelectOption } from '../../../../../components/Select';
import { Email } from '../../../../../entities/Email';
import { EmailTemplate } from '../../../../../entities/EmailTemplate';
import { EmailTemplateService } from '../../../../../services/EmailTemplateService';
import { UserService } from '../../../../../services/v1/UserService';
import { User } from '../../../../../entities/v1/User';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  applicationIds: number[];
  bcc: SelectOption[];
  cc: SelectOption[];
  toEmails: string[];
  subjectJson?: string;
  emailJson?: string;
  isLoading?: boolean;
  replyToEmail?: Email;
  setBcc: (bcc: SelectOption[]) => void;
  setCc: (cc: SelectOption[]) => void;
  setEmailJson?: (json: string) => void;
  setHtml: (html: string) => void;
  setIsValid: (valid: boolean) => void;
  setSubjectText: (subject: string) => void;
  setSubjectJson?: (json: string) => void;
}

export function EmailModalBody(props: PropTypes) {
  const [users, setUsers] = useState<User[]>(null);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>(null);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>(null);

  const [validSubject, setValidSubject] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const singleEmailRecipient = props.toEmails.length === 1;

  async function handleEmailTemplate(emailTemplate: EmailTemplate) {
    const template = singleEmailRecipient
      ? await EmailTemplateService.replaceVariables(
          emailTemplate.id,
          props.applicationIds[0],
        )
      : emailTemplate;

    setEmailTemplate(template);
  }

  useEffect(() => {
    props.setIsValid(validEmail && validSubject);
  }, [validEmail, validSubject]);

  useEffect(() => {
    (async () => {
      if (users == null) {
        setUsers(
          (
            await UserService.list({
              pageSize: 1000,
              page: 1,
            })
          ).users,
        );
      }

      if (emailTemplates == null) {
        setEmailTemplates(await EmailTemplateService.list());
      }
    })();
  }, [users]);

  return (
    <div
      className={classNames(
        'd-flex flex-column gap-3 fw-semibold fs-5',
        styles['email-modal-body'],
      )}
    >
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <EmailHeader
            cc={props.cc}
            bcc={props.bcc}
            replyToEmail={props.replyToEmail}
            users={users}
            to={props.toEmails.join(',')}
            emailTemplates={emailTemplates}
            emailTemplate={emailTemplate}
            subjectJson={props.subjectJson}
            setEmailTemplate={handleEmailTemplate}
            setCc={props.setCc}
            setBcc={props.setBcc}
            setIsValid={setValidSubject}
            setSubjectText={props.setSubjectText}
            setSubjectJson={props.setSubjectJson}
          />
          <EmailRichTextEditor
            emailTemplate={emailTemplate}
            setIsValid={setValidEmail}
            json={props.emailJson}
            onChangeHtml={props.setHtml}
            onChangeJson={props.setEmailJson}
          />
        </>
      )}
    </div>
  );
}
