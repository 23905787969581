import React, { useEffect } from 'react';
import { EmailTemplate } from '../../../../entities/EmailTemplate';
import { UpsertEmailTemplateDto } from '../UpsertEmailTemplateDto';
import { Row } from 'reactstrap';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { NameInput } from './NameInput';
import { ReferredTemplateInput } from './ReferredTemplateInput';
import { SubjectInput } from './SubjectInput';
import { EmailTemplateInput } from './EmailTemplateInput';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { CopyVariables } from './CopyVariables';
import { AttachmentDto } from '../../../CandidatePage/CandidateActions/SendEmailModal/AttachmentDto';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto, any>;
  emailTemplates: EmailTemplate[];
  errors: FieldErrors;
  isValid: boolean;
  onCancel: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<UpsertEmailTemplateDto>;
  setHtml: (html: string) => void;
  setSubjectText: (text: string) => void;
  setAttachments: (attachments: AttachmentDto[]) => void;
}

function CardHeader(props: { isValid: boolean; onCancel: () => void }) {
  return (
    <SetupPageHeader
      title={'Email Template'}
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isValid,
        },
      ]}
    />
  );
}

function getInitialReferenceTemplate(
  emailTemplates: EmailTemplate[],
  id?: number,
): EmailTemplate {
  if (id == null) {
    return null;
  }

  return emailTemplates.find(
    (emailTemplate) => emailTemplate.id.toString() === id.toString(),
  );
}

export function Body(props: PropTypes) {
  const referenceTemplateId = useWatch({
    control: props.control,
    name: 'emailTemplateReferenceId',
  });
  const referenceTemplate = getInitialReferenceTemplate(
    props.emailTemplates,
    referenceTemplateId,
  );

  useEffect(() => {
    if (referenceTemplate != null) {
      props.setAttachments(referenceTemplate.attachments);
    }
  }, [referenceTemplate]);

  return (
    <form onSubmit={props.onSubmit}>
      <CardHeader isValid={props.isValid} onCancel={props.onCancel} />
      <Row className='mt-4'>
        <NameInput register={props.register} errors={props.errors} />
      </Row>
      <Row className='my-4 d-flex align-items-center'>
        <ReferredTemplateInput
          control={props.control}
          emailTemplates={props.emailTemplates}
        />
      </Row>
      <HorizontalDivider />
      <CopyVariables />
      <Row className='my-4'>
        <SubjectInput
          control={props.control}
          referredEmailTemplate={referenceTemplate}
          setSubjectText={props.setSubjectText}
        />
      </Row>
      <EmailTemplateInput
        control={props.control}
        referredEmailTemplate={referenceTemplate}
        setHtml={props.setHtml}
      />
    </form>
  );
}
