import { EmailUserIntegration } from '../entities/EmailUserIntegration';
import { appendQueryParam } from './url';

export function isFailedIntegration(emailIntegration: EmailUserIntegration) {
  return emailIntegration?.connectionStatus === 'failed_not_authorized';
}

export function getConnectionEmailUrl(authorizeUrl: string) {
  return appendQueryParam(authorizeUrl, 'state', window.location.href);
}
