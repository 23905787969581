import React, { useEffect } from 'react';
import { AttachmentInput } from '../../../CandidatePage/CandidateActions/SendEmailModal/EmailModalBody/EmailFooter/AttachmentInput';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import { EmailSizeValidator, fileSizeBytes } from '../../../../utils/emails';
import { AttachmentInputList } from '../../../CandidatePage/CandidateActions/SendEmailModal/EmailModalBody/EmailFooter/AttachmentInputList';
import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';
import { UpsertEmailTemplateDto } from '../UpsertEmailTemplateDto';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto>;
  watch: UseFormWatch<UpsertEmailTemplateDto>;
}

export function Footer(props: PropTypes) {
  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILES = 6;

  const watchFieldArray = props.watch('attachments');

  const { fields, prepend, remove } = useFieldArray({
    name: 'attachments',
    control: props.control,
  });

  const handleRemoveField = (index: number) => {
    if (filesContent.length > index) {
      removeFileByIndex(index);
    }

    remove(index);
  };

  const [
    openFileSelector,
    { filesContent, errors, removeFileByIndex, plainFiles },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: MAX_FILE_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: { max: MAX_FILES },
    validators: [
      new EmailSizeValidator(fileSizeBytes(fields.filter((i) => i.id != null))),
    ],
  });

  useEffect(() => {
    filesContent.forEach((fileContent: FileContent, index: number) => {
      if (fields.find((f) => f.filename === fileContent.name) == null) {
        prepend({
          filename: fileContent.name,
          contentBase64: fileContent.content,
          size: plainFiles[index].size,
        });
      }
    });
  }, [filesContent]);

  return (
    <>
      <AttachmentInputList
        attachments={watchFieldArray}
        removeFileByIndex={handleRemoveField}
      />
      <AttachmentInput
        classNames='mt-4'
        filesLength={fields.length}
        errors={errors}
        maxFiles={MAX_FILES}
        maxFileSizeMb={MAX_FILE_SIZE_MB}
        openFileSelector={openFileSelector}
      />
    </>
  );
}
