import { CronofyDatePicker } from '../../../../../components/cronofy/CronofyDatePicker';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { EventSlot } from '../../../../../components/cronofy/CronofyDatePicker/EventSlot';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { InterviewDatePickerHeader } from './InterviewDatePickerHeader';
import { InterviewListItem } from '../../../../../entities/InterviewListItem';
import { Interview } from '../../../../../entities/applicant_tracking/Interview';
import {
  ScheduleFromType,
  mapScheduleFromToInt,
} from '../ScheduleFromSelect/ScheduleFromTypes';
import { InterviewerParticipant } from '../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  applicationId: number;
  applicationInterviews: InterviewListItem[];
  interview?: Interview;
  cronofyDatePickerOptions: CronofyOptions;
  participants: InterviewerParticipant[];
  meetingProvider: string;
  durationMinutes: number;
  interviewStage: InterviewStage;
  candidateView: boolean;
  selfScheduleView: boolean;
  startDate: Date;
  jobName?: string;
  scheduleFrom: ScheduleFromType;
  setScheduleFrom: (scheduleFrom: ScheduleFromType) => void;
  dateRange: number;
  setDateRange: (dateRange: number) => void;
  timezone?: string;
  interviewDatePickerFooter?: React.ReactNode;
  onConfirm: (
    eventSlot: EventSlot,
    participants?: InterviewerParticipant[],
    meetingProvider?: string,
  ) => void;
}

export const InterviewDatePickerFooterContext = createContext(null);

export function InterviewDatePicker(props: PropTypes) {
  const [timezone, setTimezone] = useState<string>(
    props.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [slotsAvailable, setSlotsAvailable] = useState<boolean>(true);

  const onConfirm = useCallback(
    (eventSlot: EventSlot) => {
      props.onConfirm(eventSlot, props.participants, props.meetingProvider);
    },
    [props.participants, props.meetingProvider],
  );

  useEffect(() => {
    setSlotsAvailable(true);
  }, [props, props.scheduleFrom, timezone]);

  return (
    <>
      <InterviewDatePickerHeader
        interviewStage={props.interviewStage}
        dateRange={props.dateRange}
        scheduleFrom={props.scheduleFrom}
        timezone={timezone}
        setDateRange={props.setDateRange}
        setScheduleFrom={props.setScheduleFrom}
        setTimezone={setTimezone}
        candidateView={props.candidateView}
        selfScheduleView={props.selfScheduleView}
        jobName={props.jobName}
      />
      <div className='mt-6h'>
        <CronofyDatePicker
          applicationInterviews={props.applicationInterviews}
          interview={props.interview}
          options={props.cronofyDatePickerOptions}
          onConfirm={onConfirm}
          durationMinutes={props.durationMinutes}
          timezone={timezone}
          participants={props.participants}
          startDate={props.startDate}
          searchDays={props.dateRange}
          startHours={mapScheduleFromToInt(props.scheduleFrom)}
          setSlotsAvailable={setSlotsAvailable}
          candidateView={props.candidateView}
        />
      </div>
      <InterviewDatePickerFooterContext.Provider
        value={{
          slotsAvailable: slotsAvailable,
          interviewerUserIds: props.participants.map(
            (participant) => participant.user.id,
          ),
          meetingProvider: props.meetingProvider,
          durationMinutes: props.durationMinutes,
          dateRange: props.dateRange,
          timezone: timezone,
          scheduleFromHours: mapScheduleFromToInt(props.scheduleFrom),
          meetingOrganizer: props.participants.find(
            (participant) => participant.isMeetingOrganizer,
          ).user,
        }}
      >
        <div className='mt-6h'>{props.interviewDatePickerFooter}</div>
      </InterviewDatePickerFooterContext.Provider>
    </>
  );
}
