import React from 'react';

import { Chip } from '../../../../../../../components/Chip';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { ClearIcon } from '../../../../../../../components/ClearIcon';
import { AttachmentDto } from '../../../AttachmentDto';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  attachments: AttachmentDto[];
  removeFileByIndex?: (index: number) => void;
}

export function AttachmentInputList(props: PropTypes) {
  if (props.attachments?.length === 0) {
    return null;
  }

  return (
    <div className='d-flex flex-wrap gap-2 mt-3'>
      {props.attachments?.map((attachment: AttachmentDto, index: number) => (
        <Chip key={index} className='text-primary bg-light-info'>
          <div className='d-flex flex-nowrap'>
            <IconSpan
              icon={{ name: 'bi-paperclip' }}
              spanText={attachment.filename}
            />
            {props.removeFileByIndex != null && (
              <ClearIcon
                classNames={classNames(
                  'ms-2 text-gray-900',
                  styles['clear-icon'],
                )}
                onClick={() => props.removeFileByIndex(index)}
              />
            )}
          </div>
        </Chip>
      ))}
    </div>
  );
}
