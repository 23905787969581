import 'chartjs-adapter-moment';
import classNames from 'classnames';
import React from 'react';
import { NpsHistory } from '../../../../entities/survey_engine/NpsHistory';
import styles from './styles.module.scss';
import { NpsBenchmark } from '../../../../entities/candidate_experience/NpsBenchmark';
import { DateFilter } from '../../../DateRangePicker';
import { AnalyticsPanel } from '../../../AnalyticsPanel';
import { OverallNpsHistoryContent } from './OverallNpsHistoryContent';
import { LazyContent } from '../../LazyContent';
import { CardTitle } from '../../../Card';
interface PropTypes {
  npsHistory: NpsHistory[];
  npsBenchmark: NpsBenchmark;
  dateRange: DateFilter;
  title: string;
  exportToPDF: boolean;
  standardNpsCalculation?: boolean;
}

export const OverallNpsHistory = React.memo(function OverallNpsHistory(
  props: PropTypes,
) {
  return (
    <AnalyticsPanel
      title={
        <div className='d-flex'>
          <div className={classNames('mb-0')}>
            <CardTitle>{props.title}</CardTitle>
          </div>
          <div className='ms-auto' data-testid='benchmark-cps-history'>
            <small className='d-block text-muted'>
              {props.npsBenchmark?.name}
            </small>
            <div className='text-end'>
              <span className={classNames(styles.activity, 'me-1')}>
                <i className='bi bi-activity'></i>
              </span>
              <span className='fw-semibold d-inline-block mb-0'>
                {props.npsBenchmark?.value
                  ? Number(props.npsBenchmark.value).toFixed(1)
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      }
    >
      <LazyContent
        isLoading={!props.npsHistory}
        isEmpty={props.npsHistory?.[0]?.data?.length === 0}
      >
        <OverallNpsHistoryContent
          npsHistory={props.npsHistory}
          npsBenchmark={props.npsBenchmark}
          dateRange={props.dateRange}
          exportToPDF={props.exportToPDF}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      </LazyContent>
    </AnalyticsPanel>
  );
});
