import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { AlertObject, AlertType } from '../../../../components/Alert';
import { EmailModalBody } from './EmailModalBody';
import { Email } from '../../../../entities/Email';
import { ConnectEmailIntegrationModal } from './ConnectEmailIntegrationModal';
import { EmailFooter } from './EmailModalBody/EmailFooter';
import { AttachmentDto } from './AttachmentDto';
import { SelectOption } from '../../../../components/Select';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import { AttachmentInputList } from './EmailModalBody/EmailFooter/AttachmentInputList';
import { removeFilesIfLimitExceeded } from '../../../../utils/emails';
import { EmailUserIntegration } from '../../../../entities/EmailUserIntegration';
import { ReconnectEmailIntegrationModalBody } from '../../../../components/ReconnectEmailIntegrationModal/Modal';
import { UserService } from '../../../../services/v1/UserService';
import {
  getConnectionEmailUrl,
  isFailedIntegration,
} from '../../../../utils/espUserIntegration';

interface PropTypes {
  applicationIds: number[];
  authorizeUrl: string;
  hasEmailConnected?: boolean;
  isOpen: boolean;
  toEmails: string[];
  description?: string;
  replyToEmail?: Email;
  onClose: (succeeded?: boolean) => void;
  setAlert?: (data: AlertObject) => void;
}

interface InnerModalPropTypes extends PropTypes {
  onClose: (succeeded?: boolean) => void;
}

export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILES = 6;

function sendEmail(
  singleEmailRecipient: boolean,
  applicationIds: number[],
  replyToEmail: Email,
  to: string,
  cc: SelectOption[],
  bcc: SelectOption[],
  subject: string,
  html: string,
  attachments: AttachmentDto[],
) {
  return singleEmailRecipient
    ? ApplicationService.sendEmail({
        applicationId: applicationIds[0],
        to: to,
        cc: cc?.map((option) => option.value),
        bcc: bcc?.map((option) => option.value),
        subject: subject,
        bodyHtml: html,
        attachments: attachments,
        replyToEmailId: replyToEmail?.id,
      })
    : ApplicationService.sendBulkEmails({
        applicationIds: applicationIds,
        to: to,
        cc: cc?.map((option) => option.value),
        bcc: bcc?.map((option) => option.value),
        subject: subject,
        bodyHtml: html,
        attachments: attachments,
        replyToEmailId: replyToEmail?.id,
      });
}

function handleAttachmentChange(
  filesContent: FileContent[],
  plainFiles: File[],
  setAttachments: (attachments: AttachmentDto[]) => void,
) {
  const attachmentsDto: AttachmentDto[] = filesContent.map(
    (fileContent: FileContent, index: number) => {
      return {
        size: plainFiles[index].size,
        filename: fileContent.name,
        contentBase64: fileContent.content,
      };
    },
  );

  setAttachments(attachmentsDto);
}

function handleClose(
  succeeded: boolean,
  onClose: (state: boolean) => void,
  setAlert?: (alert: AlertObject) => void,
) {
  let alertObject: AlertObject;

  if (setAlert) {
    if (succeeded) {
      alertObject = {
        message: 'This email was successfully sent.',
        type: AlertType.Success,
      };
    } else if (succeeded === false) {
      alertObject = {
        message: 'The email was not sent.',
        type: AlertType.Danger,
      };
    }

    setAlert(alertObject);
  }

  onClose(succeeded);
}

export function SendEmailModal(props: InnerModalPropTypes) {
  const [attachments, setAttachments] = useState<AttachmentDto[]>([]);
  const [cc, setCc] = useState<SelectOption[]>(null);
  const [bcc, setBcc] = useState<SelectOption[]>(null);
  const [emailJson, setEmailJson] = useState<string>(null);
  const [html, setHtml] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [subjectJson, setSubjectJson] = useState<string>(null);
  const [subject, setSubjectText] = useState<string>(
    props.replyToEmail?.subject,
  );
  const [emailIntegration, setEmailIntegration] =
    useState<EmailUserIntegration>(null);
  const [emailIntegrationsLoading, setEmailIntegrationsLoading] =
    useState<boolean>(true);
  const hasEmailConnected = emailIntegrationsLoading || props.hasEmailConnected;

  const singleEmailRecipient = props.toEmails.length === 1;

  const processAndSendEmail = () => {
    setIsLoading(true);
    sendEmail(
      singleEmailRecipient,
      props.applicationIds,
      props.replyToEmail,
      props.toEmails.join(','),
      cc,
      bcc,
      subject,
      html,
      attachments,
    )
      .then(() => handleClose(true, props.onClose, props.setAlert))
      .catch(() => handleClose(false, props.onClose, props.setAlert))
      .finally(() => setIsLoading(false));
  };

  const [
    openFileSelector,
    { filesContent, errors, removeFileByIndex, plainFiles },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: MAX_FILE_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: { max: MAX_FILES },
  });

  useEffect(() => {
    removeFilesIfLimitExceeded(
      attachments.length,
      plainFiles,
      errors,
      removeFileByIndex,
    );

    handleAttachmentChange(filesContent, plainFiles, setAttachments);
  }, [filesContent]);

  useEffect(() => {
    UserService.showEmailIntegration()
      .then(setEmailIntegration)
      .finally(() => setEmailIntegrationsLoading(false));
  }, []);

  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size={'lg'}
      headerTitle={
        isFailedIntegration(emailIntegration)
          ? 'Email Integration Expired'
          : 'Send Email'
      }
      bodyChildren={
        isFailedIntegration(emailIntegration) ? (
          <ReconnectEmailIntegrationModalBody
            largeImage
            url={getConnectionEmailUrl(props.authorizeUrl)}
            onClose={props.onClose}
          />
        ) : hasEmailConnected ? (
          <>
            {props.description && (
              <div className='fs-5 mb-3'>{props.description}</div>
            )}
            <EmailModalBody
              applicationIds={props.applicationIds}
              bcc={bcc}
              cc={cc}
              isLoading={isLoading}
              toEmails={props.toEmails}
              replyToEmail={props.replyToEmail}
              emailJson={emailJson}
              subjectJson={subjectJson}
              setBcc={setBcc}
              setCc={setCc}
              setEmailJson={setEmailJson}
              setHtml={setHtml}
              setIsValid={setIsValid}
              setSubjectText={setSubjectText}
              setSubjectJson={setSubjectJson}
            />
            {!isLoading && (
              <AttachmentInputList
                attachments={attachments}
                removeFileByIndex={removeFileByIndex}
              />
            )}
          </>
        ) : (
          <ConnectEmailIntegrationModal
            onClose={props.onClose}
            url={getConnectionEmailUrl(props.authorizeUrl)}
          />
        )
      }
      footerChildren={
        hasEmailConnected &&
        !isFailedIntegration(emailIntegration) &&
        !isLoading && (
          <EmailFooter
            files={plainFiles}
            errors={errors}
            onClose={props.onClose}
            isValid={isValid && !isLoading}
            onSubmit={processAndSendEmail}
            filesContent={filesContent}
            isLoading={emailIntegrationsLoading}
            openFileSelector={openFileSelector}
          />
        )
      }
    />
  );
}
