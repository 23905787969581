import { FileError, UseFilePickerConfig, Validator } from 'use-file-picker';
import { FileWithPath } from 'file-selector';

export const MAX_EMAIL_FILES_SIZE_MB = 20;

export const EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME = 'email_files_size_exceeded';

export function fileSizeBytes(files: { size: number }[]) {
  if (files.length == 0) {
    return 0;
  }
  return files.map((f) => f.size).reduce((a, b) => a + b);
}

export function removeFilesIfLimitExceeded(
  numberOfFilesUploaded: number,
  newFiles: File[],
  errors: FileError[],
  removeFileByIndex: (i: number) => void,
) {
  const filesSizeLimitExceeded =
    fileSizeBytes(newFiles) > MAX_EMAIL_FILES_SIZE_MB * 1000000;

  if (filesSizeLimitExceeded) {
    errors.push({
      name: EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME,
      plainFile: undefined,
      fileSizeToolarge: true,
    });

    let removedFiles = 0;
    while (removedFiles < newFiles.length - numberOfFilesUploaded) {
      removeFileByIndex(numberOfFilesUploaded);
      removedFiles++;
    }
  }
}

export class EmailSizeValidator implements Validator {
  initialFileSize: number;

  constructor(initialFileSize: number) {
    this.initialFileSize = initialFileSize;
  }

  async validateBeforeParsing(
    _config: UseFilePickerConfig,
    plainFiles: File[],
  ) {
    return new Promise<void>((res, rej) =>
      Array.from(plainFiles)
        .map((file) => file.size)
        .reduce((size1, size2) => size1 + size2) +
        this.initialFileSize <=
      MAX_EMAIL_FILES_SIZE_MB * 1000000
        ? res()
        : rej({ name: EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME }),
    );
  }

  async validateAfterParsing(
    _config: UseFilePickerConfig,
    _file: FileWithPath,
    _reader: FileReader,
  ) {
    return new Promise<void>((res) => res());
  }
}
